import {
  ForwardRefExoticComponent,
  MemoExoticComponent,
  RefAttributes,
  forwardRef,
  memo,
} from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import clsx from "clsx";

import classes from "./Button.module.scss";

interface Props extends ButtonProps {
  onClick?: () => void;
  sizes?: number;
}

type WithForwardedRefType = MemoExoticComponent<
  ForwardRefExoticComponent<Omit<Props, "ref"> & RefAttributes<HTMLButtonElement>>
>;

export const MuiButton: WithForwardedRefType = memo(
  forwardRef(({ color, variant, onClick, children, className, sizes, ...props }, ref) => (
    <Button
      {...props}
      ref={ref}
      style={{
        textTransform: "inherit",
        whiteSpace: "nowrap",
        fontWeight: "inherit",
        width: sizes,
        height: sizes,
      }}
      color={color}
      variant={variant}
      onClick={onClick}
      className={clsx(classes.btn, className)}
      aria-hidden="false"
    >
      {children}
    </Button>
  )),
);

MuiButton.displayName = "MuiButton";
