import { ForwardRefExoticComponent, ReactElement, RefAttributes, forwardRef } from "react";

import { ButtonProps } from "@mui/material/Button";
import clsx from "clsx";

import { MuiButton } from "../Button";
import classes from "./SliderArrow.module.scss";

const DEFAULT_ARROW_SIZE = 20;

interface Props extends ButtonProps {
  children: ReactElement | ReactElement[];
  className: string;
  onArrowClick?: () => void;
  disabled?: boolean;
  sizes?: number;
}

type WithForwardedRefType = ForwardRefExoticComponent<
  Omit<Props, "ref"> & RefAttributes<HTMLButtonElement>
>;

export const SliderArrow: WithForwardedRefType = forwardRef(
  ({ onArrowClick, children, className, disabled, sizes = DEFAULT_ARROW_SIZE, ...props }, ref) => (
    <MuiButton
      {...props}
      ref={ref}
      className={clsx(classes.btn, classes[className], {
        [classes._big]: sizes > DEFAULT_ARROW_SIZE,
      })}
      onClick={onArrowClick}
      disabled={disabled}
      sizes={sizes}
    >
      {children}
    </MuiButton>
  ),
);

SliderArrow.displayName = "SliderArrow";
