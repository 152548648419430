import { FC } from "react";

import { MenuItem, Stack } from "@mui/material";

import { MuiSelect } from "../MuiSelect";
import { TextInput } from "../TextInput";
import classes from "./CurrencyInput.module.scss";

interface Props {
  placeholder: string;
}

export const CurrencyInput: FC<Props> = ({ placeholder }) => (
  <Stack flexDirection="row">
    <MuiSelect name="currency" id="currency" className={classes.select}>
      <MenuItem value="USD">$</MenuItem>
      <MenuItem value="EUR">€</MenuItem>
      <MenuItem value="XCV">XCV</MenuItem>
    </MuiSelect>
    <TextInput name="amount" type="number" placeholder={placeholder} className={classes.input} />
  </Stack>
);
