import { FC } from "react";

import { CancelButton, GradientButton, SecondaryButton } from "@causevest/ui-kit";
import { DialogActions, Stack } from "@mui/material";

import classes from "./ModalActionsField.module.scss";

interface Props {
  onClose: () => void;
  onSubmit?: () => void;
  onPrev?: () => void;
  submitBtnText?: string;
  disabled?: boolean;
}

export const ModalActionsField: FC<Props> = ({
  onClose,
  onSubmit,
  onPrev,
  submitBtnText = "Submit",
  disabled,
}) => (
  <DialogActions sx={{ p: 0 }}>
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.footer}
    >
      <CancelButton onClick={onClose}>Cancel</CancelButton>
      <Stack flexDirection="row" justifyContent="flex-end" gap="15px">
        {!!onPrev && (
          <SecondaryButton onClick={onPrev} variant="outlined" className={classes.footer__prevBtn}>
            Previous
          </SecondaryButton>
        )}
        {!!onSubmit && (
          <GradientButton
            type="submit"
            onClick={onSubmit}
            className={classes.footer__submitBtn}
            disabled={disabled}
          >
            {submitBtnText}
          </GradientButton>
        )}
      </Stack>
    </Stack>
  </DialogActions>
);
