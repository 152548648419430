"use client";

import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Box, InputLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";

interface Props {
  label: string;
  name: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  required?: boolean;
  disablePast?: boolean;
  minDate?: Dayjs;
}

export const MuiDatePicker: FC<Props> = ({
  label,
  name,
  error,
  errorMessage,
  className,
  required,
  disablePast,
  minDate,
}) => {
  const { control } = useFormContext();

  return (
    <Box sx={{ width: "100%" }}>
      {!!label && (
        <InputLabel sx={{ fontSize: "14px", mb: "5px" }}>
          {label}&nbsp;
          {!!required && <span style={{ color: "#FE4949" }}>*</span>}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <DatePicker
              {...field}
              value={field.value ? dayjs(field.value) : null}
              format="YYYY-MM-DD"
              className={className}
              disablePast={disablePast}
              minDate={minDate}
              slotProps={{
                textField: {
                  error: error,
                  helperText: error ? errorMessage : undefined,
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};
