import React, { FC, ReactElement } from "react";

import { ButtonProps } from "@mui/material/Button";
import clsx from "clsx";

import { MuiButton } from "../Button";
import classes from "./SecondaryButton.module.scss";

interface Props extends ButtonProps {
  children: string | ReactElement | ReactElement[];
  onClick?: () => void;
  className?: string;
}

export const SecondaryButton: FC<Props> = ({ children, onClick, className, ...props }) => (
  <MuiButton {...props} className={clsx(classes.secondaryBtn, className)} onClick={onClick}>
    {children}
  </MuiButton>
);
