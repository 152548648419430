import React, { FC } from "react";

import { ButtonProps } from "@mui/material/Button";

import { MuiButton } from "../Button";
import { Icon } from "../Icon";
import classes from "./GoogleButton.module.scss";

interface Props extends ButtonProps {
  onClick?: () => void;
}

export const GoogleButton: FC<Props> = ({ onClick }) => (
  <MuiButton className={classes.googleBtn} onClick={onClick}>
    <Icon
      src="/images/google-logo.svg"
      alt="google"
      width={12}
      height={12}
      className={classes.googleBtn__icon}
    />
    <span>Continue with Google</span>
  </MuiButton>
);
