import Image, { ImageProps } from "next/image";

import { FC, memo } from "react";

import { Box } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";

interface Props extends ImageProps {
  sx?: SxProps;
}

export const Icon: FC<Props> = memo(({ className, sx, alt, ...props }) => (
  <Box className={className} sx={{ position: "relative", ...sx }}>
    <Image {...props} alt={alt ?? "image"} priority />
  </Box>
));

Icon.displayName = "Icon";
