import { FC, ReactElement, SyntheticEvent } from "react";

import { Box, Tabs } from "@mui/material";

interface Props {
  children: ReactElement[];
  value?: number;
  handleChange?: (event: SyntheticEvent, newValue: number) => void;
  className?: string;
}

export const MuiTabs: FC<Props> = ({ value = 0, handleChange, children, className }) => (
  <Box className={className} sx={{ width: "100%" }}>
    <Tabs value={value} onChange={handleChange} variant="scrollable">
      {children}
    </Tabs>
  </Box>
);
