"use client";

import { FC, ReactElement, Ref, Suspense, SyntheticEvent, useMemo, useState } from "react";

import { Box, Stack, Tab, Typography } from "@mui/material";
import clsx from "clsx";

import { Icon } from "../Icon";
import { MuiTabs } from "../MuiTabs";
import { Preloader } from "../Preloader";
import { SliderArrowsGroup } from "../SliderArrowsGroup";
import classes from "./InfoBlock.module.scss";

interface Props {
  children: ReactElement | ReactElement[];
  title?: string;
  tabs?: string[];
  headingButton?: ReactElement;
  bottomContent?: ReactElement;
  prevBtnRef?: Ref<HTMLButtonElement>;
  nextBtnRef?: Ref<HTMLButtonElement>;
  className?: string;
  innerClass?: string;
  withDecoration?: boolean;
  isLoading?: boolean;
}

export const InfoBlock: FC<Props> = ({
  title,
  tabs,
  headingButton,
  bottomContent,
  children,
  prevBtnRef,
  nextBtnRef,
  className,
  innerClass,
  withDecoration,
  isLoading,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const currentChildren = useMemo(
    () => (tabs ? (children as ReactElement[]).filter((_, key) => key === currentTab) : children),
    [children, currentTab, tabs],
  );

  const onChangeTab = (_: SyntheticEvent<Element, Event>, tab: number) => setCurrentTab(tab);

  return (
    <Stack className={clsx(classes.wrapper, className)}>
      {!!title && (
        <Stack
          className={clsx(classes.top, {
            [classes._decorated]: withDecoration,
          })}
        >
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          {prevBtnRef && nextBtnRef && (
            <SliderArrowsGroup prevBtnRef={prevBtnRef} nextBtnRef={nextBtnRef} />
          )}
          {headingButton}
        </Stack>
      )}
      {!!tabs && (
        <Stack
          className={clsx(classes.top, classes._tabs, {
            [classes._decorated]: withDecoration,
          })}
        >
          <MuiTabs value={currentTab} handleChange={onChangeTab}>
            {tabs.map((tab) => (
              <Tab key={tab} label={tab} disableRipple />
            ))}
          </MuiTabs>
        </Stack>
      )}
      <Box className={clsx(classes.inner, { [classes._decorated]: withDecoration }, innerClass)}>
        {isLoading ? (
          <Stack sx={{ height: "490px" }}>
            <Box sx={{ margin: "auto" }}>
              <Preloader />
            </Box>
          </Stack>
        ) : (
          <>
            {tabs ? (
              <Suspense
                fallback={
                  <Stack justifyContent="space-around" sx={{ minHeight: "calc(100vh - 410px)" }}>
                    <Preloader />
                  </Stack>
                }
              >
                {currentChildren}
              </Suspense>
            ) : (
              currentChildren
            )}
            {bottomContent}
          </>
        )}
      </Box>
      {withDecoration && (
        <>
          <Icon
            src="/images/palm.svg"
            alt="palm icon"
            width={128}
            height={174}
            className={clsx(classes.palm, classes._top)}
          />
          <Icon
            src="/images/palm.svg"
            alt="palm icon"
            width={128}
            height={174}
            className={clsx(classes.palm, classes._bottom)}
          />
        </>
      )}
    </Stack>
  );
};
