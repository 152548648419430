"use client";

import { useCallback, useState } from "react";

interface ReturnValue {
  isOpen: boolean;
  toggleOpen: () => void;
  open: () => void;
  close: () => void;
}

export const useElementOpen = (initialOpen: boolean): ReturnValue => {
  const [isOpen, setOpen] = useState(initialOpen);

  const toggleOpen = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const open = useCallback(() => {
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  return { isOpen, toggleOpen, close, open };
};
