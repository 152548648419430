"use client";

import { Ref, useEffect, useRef, useState } from "react";

type ReturnType<T> = [T | null, Ref<T>];

export const useSwiperRef = <T extends HTMLElement>(): ReturnType<T> => {
  const [wrapper, setWrapper] = useState<T | null>(null);
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current) {
      setWrapper(ref.current);
    }
  }, []);

  return [wrapper, ref];
};
