import Image from "next/image";

import { FC } from "react";

import { Box } from "@mui/material";

import { PreloaderSmall } from "./PreloaderSmall";

export const Preloader: FC = () => (
  <Box sx={{ m: "auto" }}>
    <Image src="/images/logo-short.svg" alt="logo" width={78} height={78} priority />
    <PreloaderSmall />
  </Box>
);
