"use client";

import { FC, SyntheticEvent, useEffect, useState } from "react";

import { Box, Tabs as MuiTabs, Tab } from "@mui/material";
import clsx from "clsx";

import classes from "./Tabs.module.scss";

const HEADER_HEIGHT = 70;
const PADDING_COEFF = 6;

interface Props {
  values: string[];
  onChange?: (idx: number) => void;
  isFeed?: boolean;
}

export const Tabs: FC<Props> = ({ values, onChange, isFeed }) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isFixedTabs, setFixedTabs] = useState(false);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);

    if (isFeed) {
      document.getElementById(String(newValue))?.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    } else if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (isFeed) {
      const handleFixedTabs = () => {
        const tabsOffset = document.getElementById("tabs")?.getBoundingClientRect().top;
        const feedEls = document.getElementsByClassName("feed_block");

        if (tabsOffset) {
          setFixedTabs(tabsOffset < HEADER_HEIGHT);
        }

        for (const item of feedEls) {
          if (item && item.getBoundingClientRect().top < HEADER_HEIGHT * PADDING_COEFF) {
            setCurrentTab(+item.id);
          }
        }
      };

      document.addEventListener("scroll", handleFixedTabs);

      return () => {
        document.removeEventListener("scroll", handleFixedTabs);
      };
    }
  }, [isFeed]);

  return (
    <Box className={classes.tabs__wrapper} id="tabs">
      <Box
        className={clsx(classes.tabs, {
          [classes._fixed]: isFixedTabs,
        })}
      >
        <MuiTabs
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={handleChange}
          className={classes.tabs__inner}
        >
          {values.map((value, key) => (
            <Tab key={value} label={value} value={key} disableRipple />
          ))}
        </MuiTabs>
      </Box>
    </Box>
  );
};
