import React, { FC } from "react";

import { ButtonProps } from "@mui/material/Button";

import { MuiButton } from "../Button";

interface Props extends ButtonProps {
  onClick: () => void;
}

export const CancelButton: FC<Props> = ({ onClick }) => (
  <MuiButton
    disableRipple
    color="error"
    variant="text"
    sx={{
      p: 0,
      justifyContent: "flex-start",
      ["&:hover"]: { backgroundColor: "#fff" },
    }}
    onClick={onClick}
  >
    Cancel
  </MuiButton>
);
