"use client";

import React, { FC, memo } from "react";

import { MuiButton } from "./Button";

interface Props {
  onClick?: () => void;
  label?: string;
}

export const DeleteButton: FC<Props> = memo(({ onClick, label = "Delete" }) => (
  <MuiButton
    sx={{
      background: "#FE4949",
      padding: "6px 8px",
      color: "#ffffff",
      ["&:hover"]: { background: "#FE4949" },
    }}
    onClick={onClick}
  >
    {label}
  </MuiButton>
));

DeleteButton.displayName = "DeleteButton";
