"use client";

import { FC, ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Box, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

interface Props {
  name: string;
  id: string;
  children: ReactElement | ReactElement[];
  extraOption?: ReactElement;
  label?: string;
  className?: string;
  error?: boolean;
  errorMessage?: string;
  fullWidth?: boolean;
  placeholderValue?: string;
  required?: boolean;
  defaultValue?: string;
}

export const MuiSelect: FC<Props> = ({
  name,
  label,
  className,
  children,
  extraOption,
  required,
  error,
  errorMessage,
  defaultValue,
  placeholderValue,
}) => {
  const { control } = useFormContext();

  return (
    <Box>
      {!!label && (
        <InputLabel sx={{ fontSize: "14px", mb: "5px" }}>
          {label}&nbsp;{required && <span style={{ color: "#FE4949" }}>*</span>}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Box sx={{ position: "relative", mb: error ? "20px" : 0 }}>
            <Select
              {...field}
              variant="outlined"
              className={className}
              error={error}
              fullWidth
              displayEmpty
            >
              {extraOption}
              {children}
            </Select>
            {!field.value && (
              <MenuItem
                value={placeholderValue}
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  minHeight: "100%",
                  color: "#000000",
                  fontWeight: 400,
                }}
                disabled
              >
                {placeholderValue}
              </MenuItem>
            )}
            {error && (
              <FormHelperText style={{ color: "#FE4949", position: "absolute", top: "100%" }}>
                {errorMessage}
              </FormHelperText>
            )}
          </Box>
        )}
      />
    </Box>
  );
};
