"use client";

import { FC } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { Box, InputLabel, OutlinedTextFieldProps, TextField, Typography } from "@mui/material";

import { onInputErrorHandle } from "../../helpers";
import classes from "./TextArea.module.scss";

interface Props extends Omit<OutlinedTextFieldProps, "variant"> {
  name: string;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  charactersNum?: number;
  wrapperClass?: string;
  placeholder?: string;
  className?: string;
  rows?: number;
}

export const TextArea: FC<Props> = ({
  name,
  label,
  error,
  errorMessage,
  required,
  charactersNum,
  wrapperClass,
  placeholder,
  className,
  rows = 4,
}) => {
  const { control } = useFormContext();
  const description = useWatch({ control, name });

  return (
    <Box className={wrapperClass} sx={{ mt: "1px" }}>
      {!!label && (
        <InputLabel sx={{ fontSize: "14px", mb: "5px" }}>
          {label}&nbsp;{required && <span style={{ color: "#FE4949" }}>*</span>}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            name={name}
            className={className}
            placeholder={placeholder}
            error={error}
            helperText={onInputErrorHandle(errorMessage)}
            multiline
            rows={rows}
            sx={{ width: "100%" }}
          />
        )}
      />
      {charactersNum && (
        <Typography
          className={classes.hint}
        >{`Minimum ${charactersNum} characters. You entered ${description.length} characters`}</Typography>
      )}
    </Box>
  );
};
