import { FC } from "react";

import { Stack, Typography } from "@mui/material";

import { Icon } from "../Icon";
import classes from "./Timing.module.scss";

interface Props {
  label: string;
}

export const Timing: FC<Props> = ({ label }) => (
  <Stack flexDirection="row" alignItems="center" gap="5px">
    <Icon
      src="/images/time.svg"
      alt="time"
      width={12}
      height={12}
      className={classes.timing__icon}
    />
    <Typography className={classes.timing}>{label}</Typography>
  </Stack>
);
