import { FC, ReactElement } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";

interface Props {
  children: ReactElement | ReactElement[];
  methods?: UseFormReturn;
}

export const DialogFormWrapper: FC<Props> = ({ children, methods }) => {
  if (methods) {
    return <FormProvider {...methods}>{children}</FormProvider>;
  }
  return <>{children}</>;
};
