import { FC } from "react";

import { Box } from "@mui/material";
import clsx from "clsx";

import classes from "./Preloader.module.scss";

export const PreloaderSmall: FC = () => (
  <Box className={classes.loading}>
    <Box className={clsx(classes.effects, classes._first)}></Box>
    <Box className={clsx(classes.effects, classes._second)}></Box>
    <Box className={clsx(classes.effects, classes._third)}></Box>
  </Box>
);
