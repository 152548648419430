import { capitalize } from "@mui/material";

function stringToColor(string: string) {
  let color = "#";
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name: string) {
  const isFullName = name.split(" ").length > 1;

  return {
    sx: {
      background: isFullName ? stringToColor(name) : "#0F69DC",
    },
    children: isFullName
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : name[0].toUpperCase(),
  };
}

const convertImgToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

export const getBase64File = async (file: File) => {
  try {
    return await convertImgToBase64(file);
  } catch (e) {
    throw new Error("Error on reading of embedded file");
  }
};

export const urlToBase64 = (url: string) => {
  if (url.includes("http://") || url.includes("https://")) {
    return fetch(url, { headers: { "Cache-Control": "no-cache" } })
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      );
  }

  return url;
};

export const parseDirtyValues = (
  dirtyFields: Record<string, unknown>,
  allValues: Record<string, unknown>,
): Record<string, unknown> | false => {
  const dirtyKeys = Object.keys(dirtyFields);

  if (dirtyKeys.length) {
    return dirtyKeys.reduce(
      (acc, dirtyKey) => {
        if (allValues[dirtyKey] !== undefined) {
          acc[dirtyKey] = allValues[dirtyKey];
        }

        return acc;
      },
      {} as Record<string, unknown>,
    );
  }

  return false;
};

export const serializeUrl = (str: string) => {
  return str
    .replace(/[^a-zA-Z&\/]+/g, "-")
    .replace(/[^a-zA-Z-]+/g, "and")
    .toLowerCase();
};

export const onInputErrorHandle = (message?: string) => (message ? capitalize(message) : undefined);
