"use client";

import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Box, InputAdornment, InputLabel, OutlinedTextFieldProps, TextField } from "@mui/material";

import { Icon } from "./Icon";

interface Props extends Omit<OutlinedTextFieldProps, "variant"> {
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  wrapperClass?: string;
  errorMessage?: string;
  fullWidth?: boolean;
  iconUrl?: string;
  disabled?: boolean;
}

export const TextInput: FC<Props> = ({
  type = "text",
  name,
  label,
  placeholder,
  className,
  wrapperClass,
  errorMessage,
  fullWidth,
  iconUrl,
  required,
  disabled,
  InputProps,
}) => {
  const { control } = useFormContext();

  return (
    <Box className={wrapperClass}>
      {!!label && (
        <InputLabel sx={{ fontSize: "14px", mb: "5px" }}>
          {label}&nbsp;{required && <span style={{ color: "#FE4949" }}>*</span>}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={type}
            placeholder={placeholder}
            className={className}
            error={!!errorMessage}
            helperText={errorMessage}
            fullWidth={fullWidth}
            disabled={disabled}
            InputProps={
              iconUrl
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon src={iconUrl} alt="icon" width={14} height={14} />
                      </InputAdornment>
                    ),
                  }
                : InputProps ?? undefined
            }
          />
        )}
      />
    </Box>
  );
};
