"use client";

import { FC, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { SecondaryButton } from "@causevest/ui-kit";
import { TextFields } from "@mui/icons-material";
import { Box, InputLabel, Stack, Typography } from "@mui/material";
import type { EditorOptions } from "@tiptap/core";
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  type RichTextEditorRef,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
} from "mui-tiptap";

import { getBase64File } from "../../helpers";
import EditorMenuControls from "./EditorMenuControls";
import useExtensions from "./useExtensions";

function fileListToImageFiles(fileList: FileList): File[] {
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || "").toLowerCase();
    return mimeType.startsWith("image/");
  });
}

interface Props {
  name: string;
  onSave?: (richText: string) => void;
  initialContent?: string;
  label?: string;
  isEditMode?: boolean;
}

const CONTENT_LENGTH_RESTRICTION = 16000000; // 16Mb

export const TextEditor: FC<Props> = ({ name, onSave, initialContent, label, isEditMode }) => {
  const { setValue } = useFormContext();
  const extensions = useExtensions({
    placeholder: "Add your own content here...",
  });
  const rteRef = useRef<RichTextEditorRef>(null);
  const [showMenuBar, setShowMenuBar] = useState(true);
  const [isError, setError] = useState(false);
  const [isEditing, setIsEditing] = useState(isEditMode || !initialContent);
  const [submittedContent, setSubmittedContent] = useState(
    rteRef.current?.editor?.getHTML() ?? initialContent,
  );

  const onSubmit = () => {
    const contentString = rteRef.current?.editor?.getHTML();

    if (contentString) {
      const textEncoder = new TextEncoder();
      const isValidLength = textEncoder.encode(contentString).length < CONTENT_LENGTH_RESTRICTION;

      if (isValidLength) {
        setSubmittedContent(contentString);
        setIsEditing(false);
        setValue(name, contentString, { shouldDirty: true });

        if (onSave) {
          onSave(contentString);
        }
      } else {
        setError(true);
      }
    }
  };

  const handleNewImageFiles = async (files: File[], insertPosition?: number): Promise<void> => {
    if (!rteRef.current?.editor) {
      return;
    }

    const attributesForImageFiles = files.map(async (file) => {
      const fileString = await getBase64File(file);

      return {
        src: fileString as string,
        alt: file.name,
      };
    });

    insertImages({
      images: await Promise.all(attributesForImageFiles),
      editor: rteRef.current.editor,
      position: insertPosition,
    });
  };

  // Allow for dropping images into the editor
  const handleDrop: NonNullable<EditorOptions["editorProps"]["handleDrop"]> = (view, event) => {
    if (!(event instanceof DragEvent) || !event.dataTransfer) {
      return false;
    }

    const imageFiles = fileListToImageFiles(event.dataTransfer.files);
    if (imageFiles.length > 0) {
      const insertPosition = view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      })?.pos;

      handleNewImageFiles(imageFiles, insertPosition);
      event.preventDefault();
      return true;
    }

    return false;
  };

  // Allow for pasting images
  const handlePaste: NonNullable<EditorOptions["editorProps"]["handlePaste"]> = (_view, event) => {
    if (!event.clipboardData) {
      return false;
    }

    const pastedImageFiles = fileListToImageFiles(event.clipboardData.files);
    if (pastedImageFiles.length > 0) {
      handleNewImageFiles(pastedImageFiles);
      return true;
    }

    return false;
  };

  return (
    <>
      <Box
        sx={{
          ".ProseMirror": {
            minHeight: isEditing ? "150px" : "auto",
          },
        }}
      >
        {!!label && <InputLabel sx={{ fontSize: "14px", mb: "5px" }}>{label}</InputLabel>}
        {isEditing ? (
          <>
            <RichTextEditor
              ref={rteRef}
              extensions={extensions}
              editorProps={{
                handleDrop,
                handlePaste,
              }}
              renderControls={() => <EditorMenuControls />}
              content={initialContent ?? submittedContent}
              immediatelyRender={false}
              RichTextFieldProps={{
                variant: "outlined",
                MenuBarProps: {
                  hide: !showMenuBar,
                },
                footer: (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      borderTopStyle: "solid",
                      borderTopWidth: 1,
                      borderTopColor: (theme) => theme.palette.divider,
                      py: 1,
                      px: 1.5,
                    }}
                  >
                    <MenuButton
                      value="formatting"
                      tooltipLabel={showMenuBar ? "Hide formatting" : "Show formatting"}
                      size="small"
                      onClick={() => setShowMenuBar((currentState) => !currentState)}
                      selected={showMenuBar}
                      IconComponent={TextFields}
                    />

                    <SecondaryButton
                      variant="outlined"
                      sx={{
                        width: "125px",
                        height: "35px",
                      }}
                      onClick={onSubmit}
                    >
                      Save
                    </SecondaryButton>
                  </Stack>
                ),
              }}
            >
              {() => (
                <>
                  <LinkBubbleMenu />
                  <TableBubbleMenu />
                </>
              )}
            </RichTextEditor>
            {isError && (
              <Typography sx={{ color: "#FE4949", fontSize: "14px", marginTop: "10px" }}>
                Content size should be less than 16Mb. Please, reduce the size.
                <br />
                Popular reasons of huge content size: huge amount of images, or huge image size.
              </Typography>
            )}
          </>
        ) : (
          <>
            <RichTextReadOnly
              content={submittedContent}
              extensions={extensions}
              immediatelyRender={false}
            />
            <Stack flexDirection="row" alignItems="flex-end" sx={{ mt: "30px" }}>
              <SecondaryButton
                variant="outlined"
                sx={{
                  width: "125px",
                  height: "35px",
                }}
                onClick={() => setIsEditing(true)}
              >
                Edit
              </SecondaryButton>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};
