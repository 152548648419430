"use client";

import { FC, ReactElement, useState } from "react";
import Dropzone from "react-dropzone";
import { FieldError, useFormContext } from "react-hook-form";

import CloseIcon from "@mui/icons-material/Close";
import { Box, InputLabel, Stack, Typography } from "@mui/material";
import clsx from "clsx";

import { getBase64File } from "../../helpers";
import { Icon } from "../Icon";
import classes from "./FileInput.module.scss";

interface Props {
  title: string;
  name?: string;
  initialImage?: string;
  instruction?: ReactElement;
  error?: FieldError;
  required?: boolean;
}

export const FileInput: FC<Props> = ({
  title,
  name = "image",
  instruction,
  initialImage,
  error,
  required,
}) => {
  const [urlString, setUrlString] = useState(initialImage ?? "");
  const { setValue } = useFormContext();

  const onFileDrop = async (acceptedFiles: File[]) => {
    const base64FileString = await getBase64File(acceptedFiles[0]);
    setUrlString(base64FileString as string);
    setValue(name, base64FileString, { shouldDirty: true });
  };

  return (
    <Stack className={classes.wrapper}>
      <InputLabel sx={{ fontSize: "14px", mb: 0 }}>
        {title}&nbsp;
        {required && <span className={classes.title__asterisk}>*</span>}
      </InputLabel>
      {!!instruction && <Typography className={classes.description}>{instruction}</Typography>}
      {urlString ? (
        <Box className={classes.embeded}>
          <Icon
            src={urlString ?? ""}
            alt="embeded file"
            style={{ objectFit: "cover" }}
            className={classes.embeded__icon}
            sizes="100%"
            fill
          />
          <CloseIcon
            fontSize="small"
            className={classes.embeded__close}
            onClick={() => setUrlString("")}
          />
        </Box>
      ) : (
        <>
          <Dropzone onDrop={onFileDrop}>
            {({ getRootProps, getInputProps }) => (
              <Stack
                {...getRootProps()}
                className={clsx(classes.dragDrop__wrapper, { [classes._error]: !!error })}
              >
                <Stack className={classes.dragDrop}>
                  <input {...getInputProps()} accept="image/png, image/jpeg" />
                  <Typography className={classes.dragDrop__title}>Drag & Drop</Typography>
                  <Typography className={classes.dragDrop__description}>
                    file here or click to upload your image (jpg or png only)
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Dropzone>
          {error?.message && (
            <Typography sx={{ color: "#FE4949", fontSize: "12px" }}>{error.message}</Typography>
          )}
        </>
      )}
    </Stack>
  );
};
