import { FC, Ref } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Stack } from "@mui/material";

import { SliderArrow } from "./SliderArrow";

interface Props {
  prevBtnRef?: Ref<HTMLButtonElement>;
  nextBtnRef?: Ref<HTMLButtonElement>;
  sizes?: number;
}

export const SliderArrowsGroup: FC<Props> = ({ prevBtnRef, nextBtnRef, sizes }) => (
  <Stack flexDirection="row" alignItems="center">
    <SliderArrow ref={prevBtnRef} className="_prev" sizes={sizes}>
      <KeyboardArrowLeftIcon />
    </SliderArrow>
    <SliderArrow ref={nextBtnRef} className="_next" sizes={sizes}>
      <KeyboardArrowRightIcon />
    </SliderArrow>
  </Stack>
);
