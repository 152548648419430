"use client";

import { FC, ReactElement } from "react";

import { Box } from "@mui/material";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

export interface SwiperSliderProps extends SwiperProps {
  children: ReactElement[];
  prevEl: HTMLButtonElement | null;
  nextEl: HTMLButtonElement | null;
  slidesPerView?: number | "auto";
  spaceBetween?: number;
  wrapperClass?: string;
  slideClass?: string;
}

export const SwiperSlider: FC<SwiperSliderProps> = ({
  children,
  slidesPerView = 1,
  spaceBetween,
  slideClass,
  prevEl,
  nextEl,
  wrapperClass,
  ...props
}) => (
  <Box className={wrapperClass}>
    <Swiper
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      modules={[Navigation]}
      navigation={{ prevEl, nextEl }}
      resizeObserver
      {...props}
    >
      {children.map((child, key) => (
        <SwiperSlide key={`slide_key_${key}`} className={slideClass}>
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  </Box>
);
